import '../App.css';

function Error() {
  return (
    <div>
      404 - Page Not Found
    </div>
  );
}

export default Error;
