import '../App.css';

function Projects() {
  return (
    <div>
      Projects
    </div>
  );
}

export default Projects;
