import '../App.css';

function Resume() {
  return (
    <div>
      Resume
    </div>
  );
}

export default Resume;
